h1, .heading-1 {
  font-size: ac(100px, 44px);
  font-weight: 600;
  line-height: 1.08;
  font-family: var(--font-main);

  @media (max-width: 639px) {
    line-height: 112%;
  }

  @mixin media 390 {
    font-size: 40px;
  }

  @mixin media 330 {
    font-size: 35px;
  }

  &.small-size {
    font-size: ac(110px, 44px);
    font-weight: 600;
    line-height: 109.09%;

    @mixin media 374 {
      font-size: 42px;
    }

    @mixin media 330 {
      font-size: 40px;
    }

    .heading-1 {
      font-size: ac(110px, 44px);
      font-weight: 600;
      line-height: 109.09%;

      @mixin media 374 {
        font-size: 42px;
      }

      @mixin media 330 {
        font-size: 40px;
      }
    }
  }

  &.smaller-size {
    font-size: ac(90px, 40px);
    font-weight: 600;
    line-height: 109.09%;

    @media (max-width: 374px) {
      font-size: 38px;
    }

    @media (max-width: 330px) {
      font-size: 36px;
    }
  }
}

h2, .heading-2  {
  font-size: ac(70px, 38px);
  font-weight: 600;
  line-height: 1.11;
  font-family: var(--font-main);

  .word {
    &:first-child {
      margin-left: ac(-26px, -14px);
      &:before {
        /*Font bug fix*/
        content: "-";
        display: inline;
        opacity: 0;
      }
    }
  }

  br {
    + span {
      margin-left: 0 !important;
    }
  }

  .word {
    &.word-ml {
      margin-left: ac(28px, 24px) !important;
    }
  }

  @media (max-width: 639px) {
    line-height: 1.2;
  }

  @media (max-width: 425px) {
    font-size: 34px;
    letter-spacing: -0.03em;
  }

  @mixin media 374 {
    font-size: 32px;
  }
}

h3, .heading-3 {
  font-size: ac(50px, 26px);
  font-weight: 600;
  line-height: 1.2;
  font-family: var(--font-main);
}

h4, .heading-4 {
  font-size: ac(34px, 22px);
  line-height: 1.18;
  font-weight: 600;
  font-family: var(--font-main);
}

h5 {
  font-size: ac(28px, 20px);
  font-weight: 500;
  line-height: 1.5;
}

h6 {
  font-size: ac(24px, 18px);
  font-weight: 500;

  line-height: 2;
}

p {
  font-family: var(--font-second);
  font-size: ac(18px, 16px);
  font-weight: 400;
  line-height: 1.55;
}

blockquote {
  font-family: var(--font-second);
  font-size: ac(18px, 16px);
  font-weight: 400;
  line-height: 1.55;
  font-style: italic;
}

li {
  font-family: var(--font-second);
  font-size: ac(18px, 16px);
  font-weight: 400;
  line-height: 1.55;
}

a {
  display: inline-block;
  font-size: ac(18px, 16px);
  font-weight: 400;
  line-height: 1.55;
}

.content-element {
  font-family: var(--font-second);
  font-size: ac(18px, 16px);
  font-weight: 400;
  line-height: 1.55;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  }

  p,
  li {
    a {
      font: inherit;
      text-decoration: underline;
      transition: all 0.3s ease;
      word-wrap: break-word;
      overflow-wrap: break-word;
      max-width: 100%;

      &:hover {
        color: var(--cl-powder);
      }
    }
  }

  &.first-big-paragraph {
    p, h1 {
      &:first-child {
        font-family: var(--font-second);
        font-weight: 500;
        font-size: ac(24px, 22px);
        line-height: 1.42;

        &:not(:last-child) {
          margin-bottom: ac(25px, 24px);
        }
      }
    }
  }

  &.first-big-paragraph-heading-1 {
    h1 {
      &:first-child {
        font-family: var(--font-second);
        font-weight: 500;
        font-size: ac(24px, 22px);
        line-height: 1.42;

        &:not(:last-child) {
          margin-bottom: ac(25px, 24px);
        }
      }
    }
  }

  h4 {
    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  /*ol,
  ul {
    li {
      list-style-position: inside;
    }
  }

  ol {
    list-style: decimal;
  }*/

  ol {
    list-style-type: none;
    counter-reset: num;
    display: grid;
    li {
      position: relative;
      display: inline-block;
      padding-left: ac(24px, 14px);
      &:before {
        position: absolute;
        color: var(--cl-black);
        top: 0;
        left: ac(5px, 0px);
        font: inherit;
        content: counter(num) '.';
        counter-increment: num;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(10px, 8px);
    }

    li ol {
      counter-reset: num;
    }

    li ul {
      counter-reset: num;
    }
  }

  ul {
    display: grid;
    li {
      position: relative;
      display: inline-block;
      align-items: center;
      padding-left: ac(24px, 14px);

      &:before {
        position: absolute;
        background: var(--cl-black);
        border-radius: 50%;
        content: '';
        width: 5px;
        height: 5px;
        top: ac(11px, 9px);
        left: ac(10px, 4px);
      }

      &:not(:last-child) {
        margin-bottom: ac(10px, 8px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(10px, 8px);
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: ac(10px, 8px);
    }
  }

  blockquote {
    &:not(:last-child) {
      margin-bottom: ac(25px, 18px);
    }
  }

  ul, ol {
    &.pt-add {
      &:not(:first-child) {
        padding-top: ac(20px, 15px);
      }
    }
  }

  h3, h4 {
    &.pt-add {
      &:not(:first-child) {
        padding-top: ac(20px, 15px);
      }
    }
  }

  &.big-size {
    font-weight: 500;
    font-size: ac(24px, 22px);
    line-height: 1.42;

    p {
      font-weight: 500;
      font-size: ac(24px, 22px);
      line-height: 1.42;
    }

    blockquote {
      p {
        font: inherit;
      }
    }
  }

  &.big-blockquote-type {
    blockquote {
      font-size: ac(34px, 22px);
      font-weight: 400;
      line-height: 129.41%;
      font-style: italic;
    }

    blockquote {
      p {
        font: inherit;
      }
    }
  }

  &.classroom-type {
    font-family: var(--font-third) !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    li {
      font-family: var(--font-third) !important;
    }
  }

  &.article-type {
    p {
      max-width: 100%;

      + h3 {
        padding-top: ac(22px, 18px);
      }

      + h4 {
        padding-top: ac(22px, 18px);
      }

      + figure {
        margin-top: ac(41px, 20px);
      }

      + img {
        margin-top: ac(41px, 20px);
      }

      + picture {
        margin-top: ac(41px, 20px);
      }
    }

    figure {
      &:not(:last-child) {
        margin-bottom: ac(50px, 30px);
      }
    }

    figcaption {
      font-family: var(--font-second);
      font-weight: 400;
      font-style: italic;
      line-height: 26px;
      /*margin-top: 15px;*/
      font-size: 14px;

      p {
        font-size: 14px;
        font-family: var(--font-second);
        font-weight: 400;
        font-style: italic;
        line-height: 26px;
      }
    }

    strong {
      + ul {
        padding-top: ac(10px, 8px);
      }

      + ol {
        padding-top: ac(10px, 8px);
      }
    }

    span {
      + ul {
        padding-top: ac(10px, 8px);
      }

      + ol {
        padding-top: ac(10px, 8px);
      }
    }

    ul,
    ol {
      + h3 {
        padding-top: ac(20px, 14px);
      }

      + h4 {
        padding-top: ac(20px, 14px);
      }

      + figure {
        margin-top: ac(41px, 20px);
      }

      + img {
        margin-top: ac(41px, 20px);
      }

      + picture {
        margin-top: ac(41px, 20px);
      }

      li {
        @media (max-width: 451px) {
          max-width: 87.2vw;

          a {
            max-width: 100%;
          }
        }
      }

    }

    h3 {
      font-size: ac(30px, 24px);
      line-height: 1.18;
      font-weight: 600;
      font-family: var(--font-main);
      margin-bottom: ac(20px, 15px);

      &:not(:last-child) {
        margin-bottom: ac(20px, 15px);
      }
    }

    h4 {
      font-weight: 700;
      font-size: ac(24px, 22px);
      line-height: 1.42;
      font-family: var(--font-main);
      margin-bottom: ac(20px, 15px);

      &:not(:last-child) {
        margin-bottom: ac(20px, 15px);
      }
    }

    ol {
      li {
        padding-left: ac(24px, 14px);
        font-size: 16px;
        line-height: 26px;

        &:not(:last-child) {
          margin-bottom: ac(10px, 8px);
        }
      }
    }

    ul {
      margin-top: ac(-2px, 0px);

      li {
        position: relative;
        display: inline-flex;
        align-items: center;
        text-indent: 0;
        font-size: 16px;
        line-height: 26px;
        padding-left: ac(24px, 14px);

        &:before {
          width: 5px;
          height: 5px;
          top: 11px;
          left: ac(10px, 4px);
        }

        &:not(:last-child) {
          margin-bottom: ac(10px, 8px);
        }
      }

      &:not(:last-child) {
        margin-bottom: ac(10px, 8px);
      }
    }

    &.privacy-type {
      ul,
      ol {
        li {
          display: block !important;
        }
      }
    }

    @media (max-width: 330px) {
      p, li {
        font-size: 15px !important;
      }
    }
  }
}

.dark-bg {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  li {
    color: var(--cl-beige);
  }

  p,
  li {
    a {
      font: inherit;
      &:hover {
        color: var(--cl-powder);
      }
    }
  }
}
