.stages-section {
  padding-top: ac(40px, 20px);
  padding-bottom: ac(40px, 20px);
  position: relative;
  z-index: 10;

  &__color-bg {
    background: var(--cl-beige);
    overflow: hidden;
    padding-top: ac(50px, 30px);
    padding-bottom: ac(50px, 30px);
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__slider-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__nav-slider {
    border-bottom: 1px solid var(--cl-black);
    width: 100%;
    max-width: 100%;
    overflow: visible;

    &:not(:last-child) {
      margin-bottom: ac(40px, 30px);
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__nav-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: min-content;
    cursor: pointer;
    margin-bottom: -1px;
    z-index: 10;

    &:not(:first-child) {
      padding-left: ac(76px, 30px);

      .stages-section {
        &__nav-icon {
          opacity: 1;
        }
      }
    }

    &.active {
      .stages-section {
        &__nav-stage {
          color: var(--cl-powder);
          /*transform: scaleX(1.1);*/
          font-weight: 700;
        }

        &__nav-title {
          color: var(--cl-powder);
          /*transform: scaleX(1.1);*/
          font-weight: 600;

          &:after {
            opacity: 1;
            width: 100%;
          }
        }

        &__nav-icon {
          i {
            color: var(--cl-powder);
          }
        }
      }
    }

    &:not(.active) {
      &:hover {
        .stages-section {
          &__nav-stage {
            color: var(--cl-royal);
          }

          &__nav-title {
            color: var(--cl-royal);
          }

          &__nav-icon {
            i {
              color: var(--cl-royal);
            }
          }
        }
      }
    }
  }

  &__nav-stage {
    font-weight: 300;
    font-size: 13px;
    line-height: 23px;
    font-family: var(--font-main);
    white-space: nowrap;
    transition: color .3s ease, font-weight .1s linear;
    transform-origin: left;

    &:not(:last-child) {
      margin-bottom: ac(6px, 5px);
    }
  }

  &__nav-title {
    font-size: ac(30px, 18px);
    line-height: 1.33;
    font-weight: 400;
    font-family: var(--font-main);
    white-space: nowrap;
    padding-bottom: 10px;
    position: relative;
    transition: color .3s ease, font-weight .1s linear;
    transform-origin: left;

    &:after {
      position: absolute;
      left: 0;
      right: auto;
      bottom: 0;
      height: 5px;
      background: var(--cl-powder);
      content: "";
      width: 0;
      transition: all .3s ease;
      opacity: 0;
    }
  }

  &__nav-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: ac(36px, 10px);
    bottom: ac(23px, 15px);
    z-index: 10;
    opacity: 0;
    pointer-events: none;

    i {
      font-size: ac(12px, 10px);
      color: var(--cl-black);
      transition: color .3s ease;
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    @media (max-width: 768px) {
      padding-top: 20px;
      border-top: 1px solid var(--cl-black);
    }
  }

  &__slider-buttons {
    .slider-btn {
      margin-top: ac(30px, 24px);
    }

    @media (min-width: 769px) {
      display: none;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__item-stage {
    font-weight: 700;
    font-size: 13px;
    line-height: 23px;
    font-family: var(--font-main);
    white-space: nowrap;
    transition: all .3s ease;
    color: var(--cl-powder);

    &:not(:last-child) {
      margin-bottom: ac(6px, 5px);
    }

    @media (min-width: 769px) {
      display: none;
    }
  }

  &__item-title {
    font-size: ac(50px, 26px);
    font-weight: 600;
    line-height: 1.2;
    font-family: var(--font-main);
    color: var(--cl-powder);
    
    &:not(:last-child) {
      margin-bottom: ac(20px, 18px);
    }
  }

  &__item-text {
    width: 100%;
    max-width: 1030px;
  }

  + .text-image-section.careers-page {
    padding-top: ac(11px, 20px);

    @media (max-width: 639px) {
      padding-top: 0;
    }
  }

  @media (max-width: 639px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}