.classroom-section {
  padding-top: ac(40px, 20px);
  padding-bottom: ac(40px, 20px);
  z-index: 10;
  overflow: hidden;

  &__color-bg {
    background: var(--cl-black);
    position: relative;
  }

  &__wrap {
    padding-top: ac(50px, 30px);
    padding-bottom: ac(50px, 30px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 33.16%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    width: 63.61%;
    margin-left: auto;
  }

  &__logo {
    @mixin aspect-ratio 449, 89;
    width: 100%;
    max-width: ac(449px, 320px);

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__title {
    width: calc(100% + ac(5px, 0px));
    padding-left: 5px;
    margin-left: -5px;

    h2 {
      letter-spacing: -0.1px;
      br {
        + span {
          margin-left: ac(28px, 24px);
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(34px, 24px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &__slider-container {
    display: flex;
    position: relative;
    width: 100%;
    @media (min-width: 640px) {
      padding-left: ac(106px, 90px);

      .slider-buttons {
        position: absolute;
        top: -3px;
        left: 0;
      }
    }

    .slider-buttons {
      .slider-btn {
        color: var(--cl-powder);

        &:hover {
          &:not(.swiper-button-disabled) {
            color: var(--cl-beige);
          }
        }
      }
    }
  }

  &__slider {
    margin-top: 10px;
    padding-right: 10px;
    width: calc(100% + 10px);
    max-width: calc(100% + 10px);
  }

  &__item {
    position: relative;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:before {
      width: 182px;
      height: 1px;
      background: var(--cl-powder);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__item-title {
    font-size: ac(24px, 20px);
    line-height: 141.67%;
    font-weight: 400;

    &:not(:last-child) {
      margin-bottom: ac(15px, 14px);
    }
  }

  &__item-text {
    padding-right: 10px;
    width: calc(100% + 10px);

    &[data-simplebar] {
      max-height: calc((ac(18px, 16px) * 1.55) * 6.1);

      .simplebar-track.simplebar-vertical {
        width: ac(4px, 3px);
        background: var(--cl-beige);
        border-radius: 0;
        .simplebar-scrollbar {
          background: var(--cl-royal);
          border-radius: 0;
          &:before {
            content: none;
          }
        }
      }
    }
  }

  @mixin media 1430 {
    .classroom-section {
      &__content {
        width: 65%;
      }

      &__title {
        h2 {
          br {
            + span {
              margin-left: ac(20px, 14px);
            }
          }
        }
      }
    }
  }

  @mixin media 1051 {
    @media (min-width: 768px) {
      .classroom-section {
        &__title {
          h2 {
            br {
              + span {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .classroom-section {
      &__image {
        width: 100%;
        bottom: auto;
        height: 600px;
        max-width: 400px;
      }
      &__wrap {
        padding-top: 640px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
      &__content {
        width: 100%;
      }
    }
  }

  @media (max-width: 639px) {
    padding-top: 0;
    padding-bottom: 0;

    .classroom-section {
      &__slider-container {
        padding-left: 0;
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-start;

        .slider-buttons {
          .slider-btn {
            margin-top: 24px;
          }
        }
      }
    }
  }

  @mixin media 451 {
    .classroom-section {
      &__image {
        height: 450px;
      }

      &__wrap {
        padding-top: 490px;
      }
    }
  }

  &.classroom-update-type {

    @media (min-width: 1450px) {
      .cont-main {
        position: relative;
      }
    }

    .classroom-section {
      &__color-bg {
        background-color: #FCDCC9;
      }

      &__bg-decor {
        position: absolute;
        inset: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: right;
        }
      }

      &__content {
        position: relative;
        z-index: 10;
      }

      &__slider-container {
        border-top: 1px solid #058668;
        padding-top: 12px;
        padding-left: 0;

        .slider-buttons {

          .slider-btn {
            color: #058668;

            &:hover {
              &:not(.swiper-button-disabled) {
                color: var(--cl-black);
              }
            }
          }

          @media (min-width: 640px) {
            z-index: 20;
            left: auto;
            right: 0;
            top: 21px;
          }
        }
      }

      &__slider {
        margin-top: 0;
      }

      &__item {
        padding-top: 0;
        &:before {
          content: none;
        }
      }

      &__item-title {
        font-weight: 600;
      }
    }
  }
}
