.text-section {
  position: relative;
  z-index: 10;
  overflow: hidden;

  &__page-decor {
    @mixin aspect-ratio 659.25, 879;
    left: ac(-206px, -100px);
    position: absolute;
    z-index: -1;
    /*top: ac(-170px, -100px);*/
    top: ac(-150px, -180px);
    width: ac(766.77, 400px);


    @media (max-width: 639px) {
      left: 0;
      width: 88.8%;
      max-width: 400px;
    }
  }

  &__wrap {
    display: flex;
  }

  &__title {
  }

  &__subtitle {
    font-size: ac(40px, 26px);
    font-weight: 600;
    line-height: 1.25;
    color: var(--cl-powder);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (min-width: 1400px) {
      letter-spacing: -0.01px;
    }
  }

  &__buttons {
  }

  &.home-page {
    margin-top: ac(20px, 40px);
    padding-top: ac(80px, 40px);
    padding-bottom: ac(15px, 16px);

    @media (max-width: 639px) {
      margin-top: 0;
    }

    .text-section {
      &__wrap {
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        grid-row-gap: ac(30px, 32px);
      }

      &__title-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      &__title {
        width: 100%;
        max-width: ac(820px, 410px);
        text-align: left;

        h2 {
          br {
            + span {
              margin-left: ac(28px, 24px);
            }
          }
        }
      }

      &__text {
        margin-top: ac(5px, 8px);
        width: calc(57.33% + 2px);
        margin-right: -2px;

        @media (max-width: 1024px) {
          width: 52%;
          margin-right: 0;
        }

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      &__buttons {
        padding-top: ac(26px, 0px);
        width: 40%;

        @media (max-width: 1024px) {
          width: 46%;
        }

        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }

  &.about-page {
    padding-top: ac(100px, 40px);
    padding-bottom: ac(40px, 20px);

    .text-section {
      &__wrap {
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        grid-row-gap: ac(35px, 32px);
      }

      &__title {
        width: 100%;
        max-width: ac(712px, 310px);
        text-align: left;

        h2 {
          br {
            + span {
              margin-left: ac(28px, 24px);
            }
          }
        }
      }

      &__content {
        width: 65.86%;
        margin-left: auto;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      &__text {
        &:not(:last-child) {
          margin-bottom: ac(50px, 24px);
        }
      }
    }

    &.about-page:not(.group-type) {
      .text-section {
        &__buttons {
          @mixin media 390 {
            width: 100%;

            .btn {
              width: 100%;

              &__text {
                width: 100%;
                text-align: center;
              }
            }
          }
        }
      }
    }

    &.group-type {
      overflow: visible;
      z-index: 2;
      padding-top: ac(40px, 20px);

      @media (min-width: 768px) {
        .text-section {
          &__wrap {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
          }

          &__title {
            width: 39.13%;
          }

          &__content {
            padding-top: ac(66px, 40px);
            width: 57.33%;
          }
        }
      }

      .text-section {
        &__group-list {
          padding-top: ac(50px, 40px);
          width: 100%;
          display: flex;
          align-items: stretch;
          justify-content: center;
          grid-row-gap: 30px;
          grid-column-gap: ac(30px, 20px);
          flex-wrap: wrap;

          .group-card {
            width: calc(32.9% - ac(15px, 10px));

            @mixin media 1100 {
              width: calc(32.6% - ac(15px, 10px));
            }

            @mixin media 851 {
              width: calc(50% - ac(15px, 10px));
            }

            @mixin media 551 {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &.our-purpose-page {
    padding-top: ac(100px, 40px);
    padding-bottom: ac(38px, 20px);

    .text-section {
      &__wrap {
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        grid-row-gap: ac(35px, 32px);
      }

      &__title {
        width: 100%;
        max-width: ac(712px, 310px);
        text-align: left;

        h2 {
          br {
            + span {
              margin-left: ac(28px, 24px);
            }
          }
        }
      }

      &__subtitle {
        width: 100%;

        @media (min-width: 1280px) {
          margin-bottom: -3px;
          margin-top: -3px;
        }
      }

      &__content {
        width: 65.86%;
        margin-left: auto;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      &__text {
        &:not(:last-child) {
          margin-bottom: ac(50px, 24px);
        }

        @media (min-width: 1280px) {
          padding-right: 22px;
        }
      }
    }
  }

  &.sector-page {
    padding-top: ac(100px, 40px);
    padding-bottom: ac(38px, 20px);

    .text-section {
      &__wrap {
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        grid-row-gap: ac(35px, 32px);
      }

      &__title {
        width: 100%;
        max-width: 100%;
        text-align: left;

        h2 {
          br {
            + span {
              margin-left: ac(28px, 24px);
            }
          }
        }
      }

      &__content {
        width: 65.86%;
        margin-left: auto;

        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
}

.only-text-section {
  padding-top: ac(80px, 40px);
  padding-bottom: ac(40px, 20px);
  position: relative;
  overflow: hidden;
  z-index: 20;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: ac(818px, 650px);
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &.second-type {
    .only-text-section {
      &__wrap {
        max-width: 100%;
      }
    }
  }

  &.columns-type,
  &.form-type {
    .only-text-section {
      &__wrap {
        max-width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row;
        grid-column-gap: ac(50px, 30px);
        grid-row-gap: 30px;

        @media (max-width: 768px) {
          flex-direction: column;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
      }
    }
  }

  &.form-type {
    .only-text-section {
      &__wrap {
        flex-direction: row;

        @media (max-width: 851px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }

      &__content {
        .form-equities-section {
          &__form-grid {
            grid-template-columns: repeat(2, minmax(0, 1fr));

            @media (max-width: 1024px) {
              grid-template-columns: repeat(1, minmax(0, 1fr));
            }
          }
        }

        .hbspt-form {
          width: 100%;
        }
      }
    }

  }

  &.pb-type {
    --none: 0;
    --small: ac(40px, 20px);
    --standard: ac(80px, 40px);
    --medium: ac(100px, 50px);
    --big: ac(120px, 60px);
    --biggest: ac(150px, 80px);

    --gap: var(--standard);

    padding-top: var(--gap);
    padding-bottom: 0;
  }
}
