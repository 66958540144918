.script-popup {
  transition: opacity 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  z-index: -100;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: auto;
    z-index: 8999;
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: -1;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

  &__block {
    background: var(--cl-white);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    width: 90%;
    max-width: ac(950px, 600px);
    padding: ac(60px, 40px) ac(40px, 24px);
  }

  &__btn-close {
    position: absolute;
    right: ac(15px, 10px);
    top: ac(15px, 10px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ac(24px, 20px);
    height: ac(24px, 20px);

    i {
      transition: inherit;
      font-size: ac(24px, 20px);
      color: var(--cl-black);
      transform: rotate(45deg);
    }

    &:hover {
      i {
        color: var(--cl-royal);
      }
    }

    @media (max-width: 360px) {
      right: 10px;
      top: 10px;
    }
  }

  &__title {
    font-size: ac(40px, 24px);
    font-weight: 600;
    line-height: 1.2;
    font-family: var(--font-main);
    color: var(--cl-black);
    text-transform: none !important;

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__content {
    max-height: calc(var(--vh, 1vh) * 66);
    width: calc(100% + 12px);
    padding-right: 12px;
    padding-top: 10px;
    padding-bottom: 10px;

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--cl-background);
      border-radius: 4px;

      .simplebar-scrollbar {
        background: var(--cl-powder);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }
  }
}
