.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ac(18px, 15px) 23px;
  height: ac(64px, 56px);
  transition: background-color 0.3s ease, border-color 0.3s ease,
  transform 0.3s ease, opacity 0.3s ease;
  background-color: var(--cl-royal);
  border: 1px solid var(--cl-royal);
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &__text {
    font-family: var(--font-main);
    font-weight: 600;
    font-size: ac(20px, 18px);
    line-height: normal;
    color: var(--cl-beige);
    position: relative;
    z-index: 2;
    transition: transform 0.3s ease;

    @media (max-width: 360px) {
      font-size: 16px;
    }
  }

  &:before {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-5deg);
    width: 148px;
    height: 170px;
    content: '';
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    background-image: url('../../assets/static/btn-bg-left.svg');
    background-size: cover;
    background-repeat: repeat;
  }

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(50%) translateY(-50%) rotate(5deg);
    width: 148px;
    height: 197px;
    content: '';
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    background-image: url('../../assets/static/btn-bg-right.svg');
    background-size: cover;
    background-repeat: repeat;
  }

  &:hover {
    border-color: var(--cl-powder);
    background-color: var(--cl-powder);

    .btn__text {
      transform: scale(1.1);
    }

    &:before {
      opacity: 0.5;
      transform: translateX(-12px) translateY(-50%) rotate(0);
    }

    &:after {
      opacity: 0.5;
      transform: translateX(12px) translateY(-50%) rotate(0);
    }
  }

  &:active {
    transform: scale(0.9);
    opacity: 0.8;
  }

  &.btn-second {
    background-color: var(--cl-beige);
    border-color: var(--cl-beige);

    .btn__text {
      color: var(--cl-black);
    }

    &:hover {
      border-color: var(--cl-powder);
      background-color: var(--cl-background);

      &:after,
      &:before {
        opacity: 0.2;
      }
    }
  }

  &.btn-classroom {
    background-color: #CBE2B8;
    border-color: #CBE2B8;

    .btn__text {
      transition: all .3s ease;
      color: #023532;
    }

    &:hover {
      border-color: var(--cl-beige);
      background-color: var(--cl-beige);
    }
  }

  &.btn-classroom-second {
    background-color: #023532;
    border-color: #023532;

    .btn__text {
      transition: all .3s ease;
      color: #CBE2B8;
    }

    &:hover {
      background-color: #CBE2B8;
      border-color: #CBE2B8;
      .btn__text{
        color: #023532;
      }
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-row-gap: ac(10px, 8px);
  grid-column-gap: ac(30px, 8px);
}

.slider-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ac(25px, 24px);
  color: var(--cl-royal);
  transition: color 0.3s ease, opacity 0.3s ease;
  cursor: pointer;

  &.swiper-button-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    &:not(.swiper-button-disabled) {
      color: var(--cl-powder);
    }
  }

  &.swiper-button-lock {
    display: none;
  }

  &.slider-btn-second {
    color: var(--cl-beige);

    &:hover {
      &:not(.swiper-button-disabled) {
        color: var(--cl-black);
      }
    }
  }

  &.slider-btn-second-mint {
    color: var(--cl-beige);

    &:hover {
      &:not(.swiper-button-disabled) {
        color: var(--cl-royal);
      }
    }
  }

  &.slider-btn-dark {
    color: var(--cl-black);

    &:hover {
      &:not(.swiper-button-disabled) {
        color: var(--cl-powder);
      }
    }
  }

  &.slider-btn-leaf {
    color: var(--cl-powder);

    &:hover {
      &:not(.swiper-button-disabled) {
        color: var(--cl-royal);
      }
    }
  }

  &.slider-btn-dark-second {
    color: var(--cl-black);

    &:hover {
      &:not(.swiper-button-disabled) {
        color: var(--cl-beige);
      }
    }
  }

  &.no-hover {
    pointer-events: none;
  }
}

.slider-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-gap: 21px;
}

.link-btn {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 12px;
  position: relative;
  cursor: pointer;

  &:before {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    height: 1px;
    background: var(--cl-black);
    content: '';
  }

  &__text {
    font-size: ac(20px, 16px);
    line-height: 33px;
    font-weight: 600;
    white-space: nowrap;

    @media (max-width: 374px) {
      font-size: 14px;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: var(--cl-black);
    transform: none !important;

    i {
      line-height: 33px;
      transform: translateY(ac(2px, 1px)) translateX(0);
      transition: transform 0.3s ease;
    }
  }

  &:hover {
    .link-btn__icon {
      i {
        transform: translateY(ac(2px, 1px)) translateX(100%);
      }
    }
  }

  &.back-type {
    &:hover {
      .link-btn__icon {
        i {
          transform: translateY(ac(2px, 1px)) translateX(-100%);
        }
      }
    }
  }

  &.splitting-type {
    &:before {
      content: none;
    }

    .link-btn {
      &__text {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;

        .word {
          border-bottom: 1px solid var(--cl-black);
          padding-right: 4px;
        }
      }

      &__icon {
        border-bottom: 1px solid var(--cl-black);
      }
    }
  }
}

.dark-bg {
  .link-btn {
    &__text {
      color: var(--cl-beige);
    }

    &__icon {
      color: var(--cl-beige);
    }

    &:before {
      background: var(--cl-beige);
    }
  }
  .link-btn .link-btn__text .word, .link-btn__icon{
    border-color: var(--cl-beige) !important;
  }
}

.buttons-links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-row-gap: ac(10px, 8px);
  grid-column-gap: ac(17px, 8px);
}

.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  border: 1px solid var(--cl-black);
  overflow: hidden;
  background: var(--cl-black);
  color: var(--cl-white);
  transition: color 0.3s ease;
  width: 25px;
  height: 25px;
  position: relative;

  i {
    position: relative;
    z-index: 5;
  }

  &:before {
    position: absolute;
    inset: 0;
    background: var(--cl-powder);
    content: '';
    transform: translateY(100%);
    transition: transform 0.3s ease;
  }

  /*i.icon-youtube {
    margin-bottom: -6px;
  }*/

  &:hover {
    color: var(--cl-black);

    &:before {
      transform: translateY(0);
    }
  }
}

.socials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
