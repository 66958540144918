.resources-section {
  position: relative;
  z-index: 10;
  padding-top: ac(40px, 20px);
  padding-bottom: ac(40px, 20px);

  &__color-bg {
    background: var(--cl-black);
    overflow: hidden;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: ac(50px, 40px);
    padding-bottom: ac(50px, 40px);
  }

  &__slider-buttons {
    .slider-btn {
      margin-bottom: 28px;
    }
  }

  &__slider {
    overflow: visible;

    &:not(.swiper-initialized) {
      display: none;
    }

    @mixin media 390 {
      max-width: 340px;
      margin-left: 0;
    }
  }

  @media (max-width: 639px) {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media (min-width: 640px) {
    .resources-section {
      &__wrap {
        align-items: center;
        grid-row-gap: 50px;
      }

      &__title {
        order: 1;
        width: calc(100% - 300px);
        margin-top: ac(-10px, -0px);
      }

      &__buttons {
        margin-top: ac(30px, 0px);
        order: 2;
      }

      &__slider {
        order: 3;
      }
    }
  }

  @media (max-width: 639px) {
    .resources-section {
      &__wrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__title {
        margin-bottom: 30px;
      }

      &__slider {
        margin-bottom: 30px;
      }
    }
  }

  &.sector-page {
    .resources-section {
      &__wrap {
        padding-top: 0;
        padding-bottom: 0;
      }

      &__buttons {
        @media (min-width: 640px) {
          margin-top: 18px;
        }
      }

      &__title {
        @media (min-width: 640px) {
          margin-top: 0;
        }
      }
    }

    @media (max-width: 639px) {
      padding-top: 20px;
      padding-bottom: 40px;
    }
  }

  &.team-member-page {
    .resources-section {
      &__buttons {
        @media (min-width: 640px) {
          margin-top: 18px;
        }
      }

      &__title {
        @media (min-width: 640px) {
          margin-top: 0;
        }
      }
    }
  }

  &.classroom-page {
    padding-top: ac(40px, 20px);
    .resources-section {
      &__title {
        h2 {
          font-family: var(--font-third) !important;

          .word {
            &:first-child {
              &:before {
                display: none !important;
              }

              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }

  &.wat-we-do-type {
    .resources-section {
      &__color-bg {
        background-color: #CBE2B8;
      }

      &__wrap {
        padding-top: ac(60px, 30px);
        grid-row-gap: ac(50px, 30px);
      }

      &__title {
        h2 {
          font-family: var(--font-third) !important;

          .word {
            &:first-child {
              &:before {
                display: none !important;
              }

              margin-left: 0 !important;
            }
          }
        }
      }

      &__slider-buttons {
        .slider-btn {
          margin-top: 28px;
          margin-bottom: 0;
        }

        @media (min-width: 769px) {
          position: absolute;
          top: -50px;
          transform: translateY(-100%);
          right: 0;
          z-index: 20;
        }
      }
    }
  }
}
