.offices-section {
  padding-top: ac(40px, 20px);
  padding-bottom: 40px;

  position: relative;
  z-index: 10;
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }

  &__title {
    display: none;
  }

  &__title-container {
    width: 100%;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__title-item {
    font-size: ac(70px, 40px);
    font-weight: 600;
    line-height: 1.11;
    font-family: var(--font-main);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;

    &.active {
      position: relative;
      left: auto;
      opacity: 1;
      pointer-events: all;
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    .filters-slider {
      &__item {
        &.active {
          .filters-slider__item-btn {
            opacity: 1;
            font-weight: 600;
          }
        }
      }

      &__item-btn {
        opacity: 0.5;
        font-weight: 400;
        font-size: ac(24px, 22px);
        line-height: 1.42;
        font-family: var(--font-main);
      }
    }
  }

  &__container {
    width: 100%;
    position: relative;
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.4s ease, transform 0.3s ease;
    pointer-events: none;

    &.active {
      position: relative;
      left: auto;
      opacity: 1;
      pointer-events: all;
    }
  }

  &__item-top-text {
    width: 100%;
    max-width: ac(940px, 600px);

    &.big-size {
      p,
      li {
        font-size: ac(30px, 20px);
        line-height: 133.33%;
        font-weight: 400;
        font-family: var(--font-main);

        /*@media (min-width: 1280px) {
          letter-spacing: -0.5px;
        }*/
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__item-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    grid-column-gap: ac(30px, 15px);
    grid-row-gap: ac(30px, 24px);

    @mixin media 1200 {
      flex-wrap: wrap;
    }
  }

  &__item-info {
    width: 40.26%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @mixin media 1200 {
      width: 100%;
    }
  }

  &__item-text {
    &:not(:last-child) {
      margin-bottom: ac(25px, 22px);
    }
  }

  &__hidden-item-text {
    margin-top: ac(-15px, -14px);
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height .4s ease, opacity .4s ease, margin .4s ease;

    &:not(:last-child) {
      margin-bottom: 0;
    }

    &.active {
      opacity: 1;
      max-height: 1000px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &__item-more {
    font-size: ac(18px, 16px);
    line-height: ac(28px, 24px);
    text-decoration: underline;
    transition: all .3s ease;
    cursor: pointer;
    font-family: var(--font-main);
    font-weight: 600;

    &:hover {
      color: var(--cl-powder);
    }

    &:not(:last-child) {
      margin-bottom: ac(25px, 22px);
    }
  }

  &__item-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__item-link-name {
    font-size: ac(16px, 14px);
    line-height: normal;
    font-weight: 600;
    color: var(--cl-powder);

    &:not(:last-child) {
      margin-bottom: ac(10px, 8px);
    }
  }

  &__item-link {
    color: var(--cl-black);
    transition: all 0.3s ease;
    font-weight: 500;
    font-size: ac(24px, 20px);
    line-height: 1.42;
    font-family: var(--font-main);

    &:hover {
      color: var(--cl-royal) !important;
    }

    &:not(:last-child) {
      margin-bottom: ac(27px, 14px);
    }

    @mixin media 1200 {
      @media (min-width: 768px) {
        br {
          display: none;
        }
      }
    }

    @media (max-width: 360px) {
      font-size: 18px;
    }
  }

  &__item-map-container {
    width: 57.33%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @mixin media 1200 {
      width: 100%;
      max-width: 712px;
    }

    @mixin media 551 {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: 20px;
    }
  }

  &__item-map {
    @mixin aspect-ratio 394, 462;
    width: 55.34%;

    @mixin media 551 {
      width: 100%;
      max-width: 100%;
    }
  }

  &__item-image {
    @mixin aspect-ratio 288, 462;
    width: 40.45%;
    max-width: 288px;

    @mixin media 551 {
      @mixin aspect-ratio 350, 440;
      width: 100%;
      max-width: 390px;
    }
  }
}
