.filters-slider {
  width: 100%;
  max-width: 100%;
  overflow: visible;

  &__item {
    input {
      display: none;

      &:checked {
        + .filters-slider__item-btn {
          border-color: var(--cl-powder);
          opacity: 1;
        }
      }
    }
  }

  &__item-btn {
    display: flex;
    width: 100%;
    border-top: 1px solid var(--cl-black);
    padding-top: ac(12px, 10px);
    font-weight: 600;
    font-size: ac(24px, 18px);
    line-height: 1.42;
    transition: border-color 0.3s ease, opacity 0.3s ease;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
