.job-search-section {
  position: relative;
  z-index: 10;
  padding-top: ac(80px, 40px);
  padding-bottom: 40px;
  overflow: hidden;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__forms-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: ac(50px, 30px);
    border-bottom: 1px solid var(--cl-black);
  }

  &__main-form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr)) 160px;
    grid-column-gap: 0;
    grid-row-gap: 10px;

    @mixin min-media 1430 {
      grid-template-columns: 401px repeat(2, minmax(0, 1fr)) 160px;
    }

    @mixin media 951 {
      grid-template-columns: repeat(3, minmax(0, 1fr));

      @media (min-width: 640px) {
        .btn {
          grid-area: 2 / 3 / 3 / 4;
        }
      }
    }

    @media (min-width: 640px) {
      .meta-input,
      .meta-select {
        position: relative;
        &:not(:first-child) {
          &:after {
            position: absolute;
            left: 0;
            top: 8px;
            bottom: 8px;
            width: 1px;
            background: var(--cl-black);
            content: '';
          }
        }
      }
    }

    @media (max-width: 639px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: 10px;
    }

    @mixin media 551 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &:not(:last-child) {
      margin-bottom: ac(24px, 22px);
    }
  }

  &__second-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__open-btn {
    grid-column-gap: 10px;

    &:before {
      bottom: -5px;
    }

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }

    &:before {
      transition: all 0.3s ease;
    }

    .link-btn {
      &__icon {
        transition: all 0.3s ease;

        i {
          transform: scaleY(1);
          transition: all 0.3s ease;
        }
      }

      &__text {
        font-size: 15px;
        line-height: normal;
        transition: all 0.3s ease;
      }
    }

    &:hover {
      .link-btn {
        &__icon {

          color: var(--cl-powder);

          i {
            transform: scaleY(-1);
          }
        }

        &__text {
          color: var(--cl-powder);
        }
      }

      &:before {
        background-color: var(--cl-powder);
      }
    }

    &.active {
      .link-btn {
        &__icon {
          transform: scaleY(-1);
        }
      }

      &:hover {
        .link-btn {
          &__icon {
            transform: none;
          }
        }
      }
    }
  }

  &__open-filters {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(21px, 10px);
    grid-row-gap: 10px;
    width: 100%;
    opacity: 0;
    /*overflow: hidden;*/
    max-height: 0;
    transition: opacity 0.3s ease, max-height 0.5s ease;

    &.active {
      opacity: 1;
      max-height: 400px;
    }

    .meta-select {
      min-width: ac(285px, 260px);
    }

    @media (max-width: 639px) {
      flex-direction: column;
      align-items: flex-start;
      max-width: 350px;

      .meta-select {
        width: 100%;
      }
    }
  }

  &__salary {
    width: ac(401px, 350px);
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 0;
    grid-row-gap: 10px;

    .meta-input,
    .meta-select {
      position: relative;
      &:not(:first-child) {
        &:after {
          position: absolute;
          left: 0;
          top: 8px;
          bottom: 8px;
          width: 1px;
          background: var(--cl-black);
          content: '';
        }
      }
    }

    @mixin media 551 {
      width: 100%;
    }
  }

  &__sort {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: ac(50px, 30px);
    grid-column-gap: 20px;

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }

    @media (max-width: 390px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .meta-select.sort-type .choices__list.choices__list--dropdown {
        right: auto;
        left: 0;
      }
    }
  }

  &__sort-info {
    p {
      font-family: var(--font-second);
      font-weight: 400;
      font-size: 14px;
    }
  }

  &__result {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: ac(30px, 16px);
    grid-row-gap: ac(31px, 16px);

    .job-card {
      background-color: transparent;
      &:hover {
        border-color: var(--cl-powder);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }

    @mixin media 961 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @mixin media 521 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}
