.form-equities-section {
  padding-top: ac(40px, 20px);
  padding-bottom: ac(40px, 20px);
  position: relative;
  z-index: 10;
  overflow: hidden;

  &__color-bg {
    background: var(--cl-beige);
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: ac(50px, 30px);
    padding-bottom: ac(50px, 30px);
  }

  &__title {
    font-size: ac(40px, 26px);
    font-weight: 600;
    line-height: 1.2;
    font-family: var(--font-main);

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  &__form-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: ac(30px, 16px);
    grid-row-gap: ac(30px, 16px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(33px, 24px);
    }

    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: 551px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &:not(:last-child) {
      margin-bottom: ac(28px, 24px);
    }

    .meta-input {
      border:1px solid var(--cl-royal);
    }
  }

  &__form-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .meta-checkbox-list__item:not(:last-child) {
      margin-bottom: ac(11px, 8px);
    }

    &:not(:last-child) {
      margin-bottom: ac(34px, 24px);
    }
  }

  &__form-bottom {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .meta-checkbox {
      &__text {
        max-width: 400px;
        a {
          color: var(--cl-royal);
          text-decoration-color: var(--cl-royal);

          &:hover {
            color: var(--cl-powder);
            text-decoration-color: var(--cl-powder);
          }
        }
      }
    }
  }

  .hbspt-form {
    width: 100%;
  }

  &.pb-type {
    --none: 0;
    --small: ac(40px, 20px);
    --standard: ac(80px, 40px);
    --medium: ac(100px, 50px);
    --big: ac(120px, 60px);
    --biggest: ac(150px, 80px);

    --gap: var(--standard);

    padding-top: var(--gap);
    padding-bottom: 0;
  }
}