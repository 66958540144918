.faq-section {
  position: relative;
  z-index: 10;
  padding-top: ac(42px, 20px);
  padding-bottom: ac(40px, 20px);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__category {
    width: 100%;
    max-width: ac(1030px, 950px);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    
    &:not(:last-child) {
      margin-bottom: ac(80px, 40px);
    }
  }

  &__category-title-block {
    background: var(--cl-black);
    width: 100%;
    padding: 10px;
    &:not(:last-child) {
      margin-bottom: ac(40px, 20px);
    }
  }

  
  &__accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    grid-row-gap: ac(30px, 20px);
    transition: min-height 0.4s ease;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    transition: background-color 0.4s ease, box-shadow 0.3s ease, padding 0.4s ease;
    position: relative;
    z-index: 2;
    padding-bottom: ac(28px, 20px);
    border-bottom: 1px solid var(--cl-black);

    &.active {
      .faq-section__item-top {
        margin-bottom: ac(25px, 14px);
      }

      .faq-section__item-btn {
        i {
          color: var(--cl-black);
          &.icon-plus {
            opacity: 0;
          }
        }
      }

      .faq-section__item-title {
        color: var(--cl-powder);
      }

      .faq-section__item-content {
        opacity: 1;
      }
    }

    &:not(.active) {
      cursor: pointer;
      &:hover {
        .faq-section__item-title {
          color: var(--cl-royal);
        }
      }

      .faq-section__item-content {
        max-height: 0 !important;
      }

      .faq-section__item-btn {
        i {
          &.icon-minus {
            opacity: 0;
          }
        }
      }
    }
  }

  &__item-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: padding-bottom 0.4s ease, margin 0.4s ease;
    padding-bottom: 0;
    margin-bottom: 0;
    position: relative;
    cursor: pointer;
  }

  &__item-btn {
    --size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    height: var(--size);
    cursor: pointer;
    transition: all 0.4s ease;
    position: relative;

    i {
      font-size: 25px;
      transition: all 0.4s ease;
      color: var(--cl-powder);
      pointer-events: none;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1;
    }

    &:hover {
      i {
        color: var(--cl-royal);
      }
    }
  }

  &__item-title {
    width: calc(100% - 40px);
    max-width: 605px;
    font-size: ac(34px, 22px);
    line-height: 1.18;
    font-weight: 600;
    font-family: var(--font-main);
    transition: all 0.3s ease;
    color: var(--cl-black);
  }

  &__item-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /*max-height: 0;*/
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.4s ease;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &__item-text {
    width: 100%;
    max-width: 924px;

    &:not(:last-child) {
      margin-bottom: ac(30px, 20px);
    }
  }
}