.fp-watermark {
    display: none;
}

html {
    scrollbar-width: thin;
    scrollbar-color: var(--cl-powder) transparent;
}

body {
    position: relative;
    min-width: 320px;
    margin: auto;
    background: var(--cl-background);

    color: var(--cl-black);
    font-family: var(--font-main);

    overflow-x: hidden;

    min-height: calc(100 * var(--vh));
    display: flex;

    flex-direction: column;
    align-items: stretch;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: var(--cl-beige);
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--cl-powder);
        border-radius: 0;
    }
}

.swiper-wrapper {
    left: 0 !important;
}

#site {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    /*&:not(.is-visible) {
      overflow: hidden;
    }

    @media (max-width: 639px) {
      overflow: hidden;
    }*/
}

.main {
    flex-grow: 1;
}

.cont {
    margin: 0 auto;
    max-width: 1336px;
    width: perc(1336);

    @media (max-width: 1024px) {
        width: 92%;
    }

    @media (max-width: 639px) {
        width: 100%;
    }

    @mixin min-media 1450 {
        width: 94.58%;
        max-width: 100%;
    }
}

.cont-second {
    width: 92.96%;
    margin: 0 auto;
    max-width: 1242px;

    @media (max-width: 639px) {
        width: 87.2%;
    }
}

.cont-main {
    width: 100%;
    max-width: 1336px;
    margin: 0 auto;
}

section,
footer,
header {
    position: relative;
    max-width: 100vw;
}

.sections-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    overflow: hidden;
}

.changing-title-animations {
    color: var(--cl-beige);
    opacity: 0;

    .title-row {
        display: inline-flex;
    }

    .title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;

        br {
            display: none;
        }
    }

    .title-line {
        position: relative;
        z-index: 1;
        min-height: ac(108px, 49px);

        &:before {
            bottom: -2.5%;
            right: ac(-18px, -4px);
            width: 0;
            height: ac(34px, 12px);
            opacity: 0;
            content: '';
            position: absolute;
            background: var(--cl-powder);
            transition: width 1s ease, opacity 1s ease;
        }

        .changing-box {
            position: relative;
            height: 100%;
            transition: width 0.8s ease;

            .changing-elem {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(0);
                white-space: nowrap;
                opacity: 0;
                transition: opacity .5s ease, transform .35s ease;
                transform-origin: left;

                &.active {
                    opacity: 1;
                    transform: translateY(-50%);
                }

                &.fix-size {

                    @media (max-width: 451px) {
                        letter-spacing: -0.05em;
                    }

                    @media (max-width: 390px) {
                        letter-spacing: -0.07em;
                    }

                    @media (max-width: 360px) {
                      letter-spacing: -0.06em;
                    }
                }

            }
        }
    }

    &.animated {
        opacity: 1;

        .title-line {
            &:before {
                opacity: 1;
                width: calc(100% + (ac(18px, 4px) * 2));
            }
        }
    }
}

.title-animation {
    opacity: 0;

    &.animated {
        opacity: 1;

        .title-line {
            &:before {
                opacity: 1;
                width: calc(100% + (ac(18px, 4px) * 2));
                background: var(--cl-powder);
            }
        }

        .splitting .word {
            animation: slide-in 0.6s cubic-bezier(0.5, 0, 0.5, 1) both;
            animation-delay: calc(100ms * var(--line-index));
            /*clip-path: polygon(110% 0, 110% 100%, 0 100%, 0 0);*/
        }
    }

    &.color-only-line {
        .title-line {
            display: inline-block;;
            position: relative;
            z-index: 1;

            &:before {
                bottom: 0;
                right: ac(-18px, -4px);
                width: 0;
                height: ac(34px, 12px);
                opacity: 0;
                content: '';
                position: absolute;
                background: var(--cl-powder);
                transition: width 1s ease, opacity 1s ease;
            }
        }

        &.animated {
            .title-line {
                &:before {
                    opacity: 1;
                    width: calc(100% + (ac(18px, 4px) * 2));
                }
            }
        }
    }
}

.second-title-animation {
    transition: all 0.3s ease;
    opacity: 0;
    transform: scaleX(1.1) translateX(5%);
    transform-origin: top left;

    &.animated {
        opacity: 1;
        transform: scaleX(1) translateX(0);
    }
}

@keyframes slide-in {
    from {
        transform: translateX(0.5em) rotate(0);
        opacity: 0;
        /*clip-path: polygon(110% 80%, 110% 100%, 0 100%, 0 80%);*/
    }
}

.swiper {
    width: 100%;
    max-width: 100%;
}

.logo {
    transition: 0.3s ease;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &:hover {
        transform: scale(1.04);
    }
}

.sticky-buttons-container {
    position: absolute;
    padding-top: 40vh;
    top: 0;
    right: 0;
    bottom: 0;
}

.sticky-buttons {
    position: sticky;
    right: 0;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ac(17px, 8px);
    z-index: 500;
    transform: rotate(-90deg) translateY(-50%) translate(50%);
    transform-origin: right;
    margin-top: 100vh;

    .btn {
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24),
        -2px 0px 32px rgba(0, 0, 0, 0.12);
        height: ac(64px, 40px);

        &__text {
            font-size: ac(20px, 16px);
        }
    }

    @media (max-width: 639px) {
        /*display: none;*/
        top: 68vh;

        .btn {
            height: 40px;

            &__text {
                font-size: 16px;
                white-space: nowrap;
            }
        }
    }

    @mixin media-height 800 {
        top: 40%;
    }

    @mixin min-media-height 800 {
        margin-top: 90vh;
    }

    @media (max-width: 639px) {
        margin-top: 80vh;
        top: 40vh;

        .btn {
            height: 40px;

            &__text {
                font-size: 16px;
                white-space: nowrap;
            }
        }
    }

    &.insight-details-page {
        margin-top: 77vh;

        @mixin media 1025 {
            @media (min-width: 640px) {
                margin-top: 360px;
            }
        }
    }


}

#bold-credits {
    width: 150px;
    transition: ease-in-out 0.25s;

    &::before,
    &::after {
        display: none;
    }

    &:hover {
        transform: scale(1.1);
    }
}

.error-section {
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &__page-decor {
        @mixin aspect-ratio 1440, 1365;
        position: absolute;
        left: 0;
        width: 100%;
        /*top: ac(-52px, -40px);*/
        top: ac(-102px, -80px);
        z-index: 2;

        @media (max-width: 639px) {
            width: 1440px;
            margin-left: -530px;
            top: -110px;
        }

        @mixin min-media 1600 {
            top: -200px;
        }

        @mixin min-media 1800 {
            top: -400px;
        }
    }

    &__color-bg {
        position: relative;

        &:before {
            inset: 0;
            z-index: -1;
            background: var(--cl-black);
            content: '';
            position: absolute;
        }
    }

    &__wrap {
        min-height: 88vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 10;
        width: 100%;

        @media (max-width: 639px) {
            min-height: 100vh;
        }
    }

    &__title {
        position: relative;
        z-index: 10;

        &:not(:last-child) {
            margin-bottom: ac(50px, 30px);
        }

        h1 {
            text-align: center;
            color: var(--cl-beige) !important;
        }
    }

    &__text {
        text-align: center;

        &:not(:last-child) {
            margin-bottom: ac(30px, 24px);
        }
    }
}

.grecaptcha-badge {
    z-index: 100;
    opacity: 0;
    pointer-events: none;
}

.btn-to-top {
    --size: ac(64px, 40px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: ac(64px, 40px);
    height: ac(64px, 40px);
    z-index: 101;
    right: 0;
    bottom: ac(52px, 40px);
    opacity: 0;
    transition: all .4s ease;
    background: var(--cl-royal);
    pointer-events: none;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24),
    -2px 0px 32px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transform-origin: right;
    transform: scaleX(0);
    color: var(--cl-beige);

    i {
        font-size: 24px;
        transition: all .3s ease;
    }

    &:hover {
        background-color: var(--cl-powder);

        i {
            transform: scale(1.2);
        }
    }

    &.is-visible {
        opacity: 1;
        pointer-events: auto;
        transform: scaleX(1);
    }
}

.dark-bg {
    color: var(--cl-beige);

    .text-block-section__value {
        border-color: var(--cl-beige);
    }

    .credentials-section__item-count, .testimonials-section__title, .job-card__text {
        color: var(--cl-beige);
    }

    &.resource-card__category p {
        color: inherit;
    }
}

.cookie-terms-sections {
    .content-element.article-type {
        ul, ol {
            li {
                font-size: ac(18px, 16px);
            }
        }
    }
}

.text-section__content, .sectors-section__text{
    h1{
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
        margin-bottom: 10px;
        font-family: inherit;
    }
}