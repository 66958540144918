.job-card {
  border: 1px solid var(--cl-black);
  padding: ac(26px, 19px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: var(--cl-royal);
  height: auto;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  position: relative;

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: ac(0px, 2px);
    padding-right: ac(40px, 30px);
    margin-top: auto;

    li {
      font-size: ac(18px, 14px);
      line-height: 1.56;
      font-weight: 700;
      font-family: var(--font-main);

      @media (max-width: 639px) {
        line-height: 1.43;
      }

      &:not(:last-child) {
        margin-bottom: ac(0px, 3px);
      }
    }
  }

  &__subtitle {
    font-family: var(--font-second);
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px;
  }

  &__title {
    font-size: ac(30px, 26px);
    line-height: 1.33;
    font-weight: 400;
    @mixin max-line-length 4;

    @media (max-width: 1024px) {
      font-size: 26px;
    }

    @media (max-width: 639px) {
      line-height: 1.31;
    }

    &:not(:last-child) {
      margin-bottom: ac(15px, 12px);
    }
  }

  &__text {
    @mixin max-line-length 4;
    width: 100%;
    max-width: 100%;

    font-family: var(--font-second);
    font-weight: 400;
    line-height: 1.55;
    font-size: ac(18px, 16px);

    p,
    li {
      font-size: ac(18px, 16px);
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(24px, 20px);
    position: absolute;
    z-index: 2;
    right: ac(26px, 19px);
    bottom: ac(27px, 19px);
  }

  &:hover {
    border-color: var(--cl-beige);
    background-color: rgba(100, 178, 164, 0.7);
  }
}

.dark-bg {
  .job-card {
    border-color: var(--cl-beige);

    &__icon {
      color: var(--cl-beige);
    }

    &:hover {
      border-color: var(--cl-black);
    }
  }
}
